a {
    text-decoration: none;
    

}

a:hover {
    text-decoration:underline;
    text-decoration-thickness:3px;
    text-underline-offset: 0.5em;
}

.close_btn
{  
    width:36px;
    height:36px;
    align-self:end;
    font-size:32px;
    border:none;
    cursor:pointer;
}